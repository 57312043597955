<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    page: {
        title: "Buat Tempat Uji Kompetensi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Buat Tempat Uji Kompetensi",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "Buat Tempat Uji Kompetensi",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,
            id_verifikasi_tuk: this.$route.params.id,

            meta_asesor: [
                {
                    'tuk': 'Ruang Meeting LSP',
                    'tanggal_asesmen': '1 Agustus 2023'
                },
            ],
                        // self.getNomorVerifikasi();
                        // self.getNomorVerifikasi();
            form_surat_penetapan : {
                tanggal_surat_penetapan : new Date().toISOString().substr(0, 10),
                nomor_verifikasi : null,
                perihal : 'PENETAPAN PERSYARATAN TEKNIS TEMPAT UJI KOMPETENSI LEMBAGA SERTIFIKASI PROFESI HOLDING BUMN FARMASI',
                keterangan : '',
                verifikasi_id : this.$route.params.id,
                tuk : [],
            },
            data_verifikasi : [],
        };
    },
    mounted() {
        this.getDetailVerifikasi();
    },
    methods: {
        getDetailVerifikasi() {            
            let self = this;
            self.loadingTable = true;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/show",
                params: {
                    id: self.id_verifikasi_tuk,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.data_verifikasi = data_edit;
                        self.form_surat_penetapan.nomor_verifikasi = data_edit.surat_verifikasi_nomor;
                        self.form_surat_penetapan.nama_skema_sertifikasi = data_edit.skema.nama_skema_sertifikasi;
                        // self.form_surat_penetapan.tuk = data_edit.verifikasi_tuk_asesor;
                        data_edit.verifikasi_tuk_asesor.forEach(function (item) {
                            self.form_surat_penetapan.tuk.push({
                                id : item.id,
                                tuk_id : item.tuk_id,
                                tuk_nama : item.tuk_nama,
                                tanggal_asesmen : moment(item.tanggal_asesmen).format('YYYY-MM-DD'),
                            });
                        });
                        self.loadingTable = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
     
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        generateSuratPenetapan() {
            let self = this;
            self.showLoadingPreviewSurat = true;
            
        },
        StoreData() {
            let self = this;
            Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Tunggu Sebentar",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                self.showModalSimpan = false;
                self.$router.push({ name: "detail-verifikasi_tuk_penggunaan", params: { id: 1 }})
                }
            });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>FORMULIR SURAT PENETAPAN TEMPAT UJI KOMPETENSI</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomer Verifikasi</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_surat_penetapan.nomor_verifikasi">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nama Skema Sertifikasi</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_surat_penetapan.nama_skema_sertifikasi">
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Surat Penetapan</label>
                                                <input type="date" name="" id="" class="form-control" v-model="form_surat_penetapan.tanggal_surat_penetapan">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_surat_penetapan.perihal">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Daftar TUK</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>TUK</th>
                                                    <th>Tanggal Asesmen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="meta_asesor.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in form_surat_penetapan.tuk" :key="index">
                                                    <td>
                                                        <input type="text" class="form-control" v-model="item.tuk_nama">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" v-model="item.tanggal_asesmen">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <h3>PREVIEW SURAT PENETAPAN TUK</h3>
                                        <div class="row p-4">
                                            <div class="card" style="height: 250px;outline-style: outset;">
                                                <div class="card-body">
                                                    <div v-if="showLoadingPreviewSurat">
                                                        <div class="text-center">
                                                            <i class="fas fa-spinner fa-spin">Loading Generate Surat</i>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="text-center">
                                                            <iframe v-bind:src="PreviewPDF" width="100%" height="400"></iframe>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <b-button type="button" class="m-1" variant="warning"><i
                                                class="fas fa-download"></i>
                                            Unduh PDF</b-button>
                                        <b-button v-on:click="modalSimpan" type="submit" variant="primary" class="m-1"><i
                                                class="fa fa-save"></i>
                                            Simpan</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>   
                </div>
            </div>
        </div>
    </Layout>
    <!-- <b-modal v-model="showModalSimpan" title="Konfigurasi Atasan" title-class="text-black font-18" body-class="p-3"
        size="md" hide-footer>
        <div class="row">
            <form @submit.prevent="StoreData">
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Sertifikasi</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Sertifikasi</option>
                        <option value="">Manajer Sertifikasi 1</option>
                        <option value="">Manajer Sertifikasi 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Manajer Pelatihan</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Manajer Pelatihan</option>
                        <option value="">Manajer Pelatihan 1</option>
                        <option value="">Manajer Pelatihan 2</option>
                    </select>
                </div>
                <div class="form-group col-md-12">
                    <label for="tipe_menu">Ketua TUK</label>
                    <select name="" id="" class="form-control">
                        <option value="">Pilih Ketua TUK</option>
                        <option value="">Ketua TUK 1</option>
                        <option value="">Ketua TUK 2</option>
                    </select>
                </div>

                <div class="col-md-12 text-end mt-3">
                    <button class="btn btn-primary" type="submit"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal> -->
</template>
